
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function manufacturersContainerRT () {
    function repeatHierarchicalGroups1(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                return this.imageUrl ? _createElement('div', {
                    'className': 'cm_vehicle-categories_category-wrapper',
                    'key': '5'
                }, [_createElement('div', {
                        'className': 'cm_vehicle-categories_category-container',
                        'key': '870'
                    }, _createElement('a', {
                        'href': this.href,
                        'className': 'cm_vehicle-categories_link'
                    }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container' }, this.imageUrl ? _createElement('img', {
                        'alt': window.Convermax.removeSpecialCharacters(this.value),
                        'title': window.Convermax.removeSpecialCharacters(this.value),
                        'className': 'cm_vehicle-categories_category-image',
                        'src': this.getImageOrDefault(this.imageUrl),
                        'key': '285'
                    }) : null, !this.imageUrl ? _createElement('h3', { 'key': '535' }, this.value) : null), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container' }, _createElement('div', { 'className': 'cm_vehicle-categories_category-title' }, this.value), _createElement('svg', {
                        'className': 'icon chevron',
                        'title': 'icon chevron right'
                    }, _createElement('path', { 'd': 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' })))))]) : null;
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-categories cm_vehicle-categories__manufacturers cm_vehicle-categories__flat' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-categories_category-wrapper-repeater  cmRepeater_hierarchicalGroups' },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups1.bind(this))
    ]));
}
        export const componentNames = []